window.Client = {
    init: function () {
        var checkbox = document.querySelector('input[data-label="coreg_optin_haus"]');
        if (checkbox) {
            checkbox.checked = false;
        }
    },

    onRender: function (screenId) {
        // Do something when the flow renders
    }
}